/**
 * Chunks an array into smaller arrays of a specified size.
 * @example
 * chunkArray([1,2,3,5,6], 4) // [[1,2,3,4], [5,6]]
 * @returns {Array} An array of arrays
 * @category Utils
 */
export const chunkArray = <T>(
  arr: T[],
  size: number
): T[][] => {
  const chunks: T[][] = []
  for (let i = 0; i < arr.length; i += size)
    chunks.push(arr.slice(i, i + size))

  return chunks
}
